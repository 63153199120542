<template>
  <div class="px-6 py-8">
    <div class="flex justify-between">
      <div class="text-3xl font-semibold">配方登记板</div>
      <img src="https://resource.enoch-car.com/enocloud/print-enoch-logo.png" class="w-53 h-6" />
    </div>

    <div class="relative mt-5 w-30 h-6 flex items-center justify-center">
      <img src="https://resource.enoch-car.com/enocloud/print-title-bg.png" class="absolute left-0 top-0 right-0 bottom-0" />
      <span class="z-1 text-sm text-white font-semibold">修补车辆信息</span>
    </div>

    <div class="mt-5 grid grid-cols-6 gap-y-3 gap-x-3 text-sm">
      <div class="flex justify-end font-semibold">色号</div>
      <div class="border-b border-b-solid border-gray-200 font-semibold">{{ data.colorCode }}</div>

      <div class="flex justify-end font-semibold">颜色名称</div>
      <div class="border-b border-b-solid border-gray-200 font-semibold break-all">{{ data.colorName }}</div>

      <div class="flex justify-end font-semibold">修补车型</div>
      <div class="border-b border-b-solid border-gray-200 font-semibold">
        {{ data.vehicleSpec && data.vehicleSpec.join(',') }}
      </div>

      <div class="flex justify-end font-semibold">修补部位</div>
      <div class="border-b border-b-solid border-gray-200 font-semibold"></div>

      <div class="flex justify-end font-semibold">喷漆层数</div>
      <div class="border-b border-b-solid border-gray-200 font-semibold"></div>

      <div class="flex justify-end font-semibold">底漆颜色</div>
      <div class="border-b border-b-solid border-gray-200 font-semibold"></div>

      <div class="flex justify-end font-semibold">车辆生产年月</div>
      <div class="border-b border-b-solid border-gray-200 font-semibold">{{ data.startYear }}</div>

      <div class="flex justify-end font-semibold">登记日期</div>
      <div class="border-b border-b-solid border-gray-200 font-semibold">{{ formatDate(new Date()) }}</div>
    </div>

    <div class="relative mt-5 w-30 h-6 flex items-center justify-center">
      <img src="https://resource.enoch-car.com/enocloud/print-title-bg.png" class="absolute left-0 top-0 right-0 bottom-0" />
      <span class="z-1 text-sm text-white font-semibold">配方明细(克)</span>
    </div>

    <div :class="['mt-5 grid gap-3 text-sm', pearls?.length ? 'grid-cols-2' : 'grid-cols-1']">
      <div class="pb-2 table border-b border-b-solid border-gray-200">
        <div class="table-header-group">
          <div class="table-row">
            <div class="table-cell">色母编号</div>
            <div class="table-cell">实加重量</div>
          </div>
        </div>
        <div class="table-row-group">
          <div v-for="item of colors" class="table-row">
            <div class="table-cell">{{ item.goods?.workshopGoodsSpecification?.goods.serialNo }}</div>
            <div class="table-cell">{{ item.weight }}</div>
          </div>
        </div>
      </div>

      <div v-if="pearls.length" class="pb-2 table border-b border-b-solid border-gray-200">
        <div class="table-header-group">
          <div class="table-row">
            <div class="table-cell">色母编号</div>
            <div class="table-cell">实加重量</div>
          </div>
        </div>
        <div class="table-row-group">
          <div v-for="item of pearls" class="table-row">
            <div class="table-cell">{{ item.goods?.workshopGoodsSpecification?.goods.serialNo }}</div>
            <div class="table-cell">{{ item.weight }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-[#1a7d7b] grid grid-cols-4 px-20 py-2 text-sm text-white">
    <div class="flex justify-center font-semibold">审核人</div>
    <div class="border-b border-b-solid border-white"></div>
    <div class="flex justify-center font-semibold">日期</div>
    <div class="border-b border-b-solid border-white"></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{ data: EnospraySprayDefinitions['SprayFormulaDto'] }>()

const colors = computed(() => props.data.colors)

const pearls = computed(() => props.data.pearls)
</script>
