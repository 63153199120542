<template>
  <print-header title="采购单"></print-header>

  <print-table>
    <tr>
      <th>供应商</th>
      <td>{{ data.supplier?.name }}</td>
      <th>采购单号</th>
      <td colspan="5">{{ data.serialNo }}</td>
    </tr>
    <tr>
      <th>采购日期</th>
      <td>{{ formatDate(data.datetime) }}</td>
      <th>仓库</th>
      <td>{{ data.warehouse?.name }}</td>
      <th>经手人</th>
      <td>{{ data.auditBy?.name }}</td>
      <th>运费</th>
      <td>{{ data.freight }}</td>
    </tr>
    <tr>
      <th>采购备注</th>
      <td colspan="3">{{ data.comment }}</td>
      <th>操作员</th>
      <td>{{ data.preparedBy?.name }}</td>
      <th>税率</th>
      <td>{{ formatPercent(data.taxRate) }}</td>
    </tr>
  </print-table>

  <print-table
    :data="data.purchaseGoods"
    :columns="[
      { label: '序号', type: 'index' },
      { label: '编码', prop: 'goods.serialNo' },
      { label: '配件名称', prop: 'goods.name' },
      { label: 'OE号', prop: 'goods.oem' },
      { label: '单位', prop: 'goodsSpecification.name' },
      { label: '数量', prop: 'count' },
      { label: '税率', prop: 'inventory.taxRate' },
      { label: '不含税单价', prop: 'preTaxPrice', format: 'formatMoney' },
      { label: '含税单价', prop: 'price', format: 'formatMoney' },
      { label: '不含税金额', prop: 'preTaxAmount', format: 'formatMoney' },
      { label: '含税金额', prop: 'amount', format: 'formatMoney' },
      { label: '仓位', prop: 'warehouseShelf' }
    ]"
  >
    <template #append>
      <tr>
        <th rowspan="2">合计</th>
        <td colspan="8">数量: {{ summary?.count }}</td>
        <th colspan="2">小写金额</th>
        <td>{{ summary?.amount }}</td>
      </tr>
      <tr>
        <td colspan="8"></td>
        <th colspan="2">金额大写</th>
        <td>{{ toMoney(summary?.amount) }}</td>
      </tr>
      <tr>
        <td colspan="12">
          <div class="flex justify-between">
            <span>总金额: {{ summary?.amount }}</span>
            <span>总金额大写: {{ toMoney(summary?.amount) }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="12">签字: _____</td>
      </tr>
    </template>
  </print-table>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { calculator } from '@enocloud/utils'

const props = defineProps<{ data: EnocloudInventoryDefinitions['PurchaseDto'] }>()

const summary = computed(() => {
  return props.data.purchaseGoods?.reduce(
    (res, item) => {
      res.count = calculator.add(res.count, item.count)
      res.amount = calculator.add(res.amount, calculator.mul(item.count, item.price))
      res.preTaxAmount = calculator.add(res.preTaxAmount, item.preTaxAmount)
      res.cost = calculator.add(res.cost, calculator.mul(item.price, item.count))
      return res
    },
    {
      count: 0,
      amount: 0,
      preTaxAmount: 0,
      cost: 0
    }
  )
})
</script>
