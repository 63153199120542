import { createApp } from 'vue'
import { formatDate, formatMoney, formatNumber, formatPercent, toMoney, useStore } from '@enocloud/utils'

import PrintTable from '@print/common/print-table.vue'
import PrintHeader from '@print/common/print-header.vue'

import type { ComponentOptions } from 'vue'

interface Options<P> {
  props: P
  template?: '/static/print.html'
}

export const print = <P>(component: ComponentOptions<P>, options?: Options<P>) => {
  let printWindow: Window | null
  const onload = () => {
    const app = createApp(component, { ...options?.props })

    const store = useStore()

    app.config.globalProperties.formatDate = formatDate
    app.config.globalProperties.formatMoney = formatMoney
    app.config.globalProperties.formatNumber = formatNumber
    app.config.globalProperties.formatPercent = formatPercent
    app.config.globalProperties.toMoney = toMoney
    app.config.globalProperties.store = store

    app.component('print-header', PrintHeader)
    app.component('print-table', PrintTable)

    app.mount(printWindow?.document.querySelector('#main')!)
  }

  printWindow = window.open(options?.template ?? '/static/print.html')
  printWindow && (printWindow.onload = onload)
}

export * from './template'
