<template>
  <table class="w-full border-collapse border-spacing-0">
    <template v-if="$slots.default">
      <slot></slot>
    </template>

    <template v-else>
      <colgroup>
        <col v-for="column of columns" :width="column.width" />
      </colgroup>

      <thead>
        <tr>
          <th v-for="column of columns" class="">{{ column.label }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) of data">
          <td v-for="column of columns" class="">
            <template v-if="column.type === 'index'">{{ index + 1 }}</template>
            <template v-else>
              <span v-if="column.format === 'formatMoney'">{{ formatMoney(result(item, column.prop)) }}</span>
              <span v-else-if="column.format === 'formatDate'">{{ formatDate(result(item, column.prop)) }}</span>
              <span v-else-if="column.format === 'formatNumber'">{{ formatNumber(result(item, column.prop)) }}</span>
              <span v-else>{{ result(item, column.prop) }}</span>
            </template>
          </td>
        </tr>

        <slot name="append"></slot>
      </tbody>
    </template>
  </table>
</template>

<script setup lang="ts">
interface Column {
  align?: 'center' | 'right' | 'left'
  label: string
  prop?: string
  width?: string | number
  type?: 'index'
  format?: 'formatMoney' | 'formatDate' | 'formatNumber'
}

interface Props {
  data?: unknown[]
  columns?: Column[]
}

const props = defineProps<Props>()

const result = (data: unknown, key?: string | Array<string>, defaultValue?: any) => {
  if (data && typeof data === 'object') {
    const keys = key ? (Array.isArray(key) ? key : key?.split('.')) : []
    return keys?.reduce((data, key) => data?.[key], data as Record<string, any>) || defaultValue
  } else {
    return defaultValue
  }
}
</script>
